@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.edit-description {
  &__header-wrapper {
    display: flex;
    gap: spacing.$s;
    align-items: center;
    margin-bottom: spacing.$l;
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: light.$surface-primary-default;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$xl;
  }
}
